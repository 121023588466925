<template>
  <div class="text-center">
    <v-navigation-drawer
      v-model="isVisible"
      :bottom="true"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
      app
    >
      <v-card height="100%">
        <div class="drawer-header d-flex align-center mb-4">
          <span class="font-weight-semibold text-base text--primary">
            {{ formType === 'editItem'?'Edit':'Add' }} Outlet
          </span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isVisible=false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="onSubmit"
          >
            <div>
              <v-tabs v-model="tab">
                <v-tab key="details">
                  Details
                </v-tab>
                <v-tab key="image">
                  Image
                </v-tab>
              </v-tabs>

              <v-tabs-items
                v-model="tab"
                class="pt-5"
              >
                <v-tab-item key="details">
                  <v-text-field
                    v-model="formData.name"
                    outlined
                    dense
                    label="Name *"
                    hide-details="auto"
                    class="mb-6"
                    :rules="[validators.required]"
                    :error-messages="errors.name"
                    @change="errors.name = []"
                  ></v-text-field>
                  <!-- Google address search -->
                  <div class="forms">
                    <GmapAutocomplete
                      color="primary"
                      placeholder=""
                      outlined
                      dense
                      hide-details="auto"
                      @place_changed="addressSelected"
                    ></GmapAutocomplete>
                    <label class="">Search Address</label>
                  </div>
                  <GmapMap
                    ref="mapRef"
                    :center="initial"
                    :zoom="16"
                    :options="options"
                    map-type-id="terrain"
                    style="width: auto; height: 300px; margin-bottom:15px;"
                  >
                    <GmapMarker
                      v-if="formData.lat!=null"
                      :position="formData"
                      :clickable="false"
                      :draggable="true"
                      @dragend="markerMoved"
                    />
                  </GmapMap>
                  <v-text-field
                    v-model="formData.street_address"
                    outlined
                    dense
                    label="Street address *"
                    hide-details="auto"
                    class="mb-6"
                    :rules="[validators.required]"
                    :error-messages="errors.street_address"
                    @change="errors.street_address = []"
                  ></v-text-field>
                  <v-text-field
                    v-model="formData.city"
                    outlined
                    dense
                    label="City *"
                    hide-details="auto"
                    class="mb-6"
                    :rules="[validators.required]"
                    :error-messages="errors.city"
                    @change="errors.city = []"
                  ></v-text-field>
                  <v-text-field
                    v-model="formData.postal_code"
                    outlined
                    dense
                    label="Postal code *"
                    hide-details="auto"
                    class="mb-6"
                    :rules="[validators.required]"
                    :error-messages="errors.postal_code"
                    @change="errors.postal_code = []"
                  ></v-text-field>
                  <!-- Country selection -->
                  <v-select
                    v-model="formData.country_id"
                    :items="countries"
                    item-text="nicename"
                    item-value="id"
                    label="Country *"
                    outlined
                    dense
                    :rules="[validators.required]"
                    :error-messages="errors.country_id"
                    @change="errors.country_id = []"
                  ></v-select>
                  <v-text-field
                    v-model="formData.lat"
                    outlined
                    dense
                    label="Latitude *"
                    hide-details="auto"
                    class="mb-6"
                    :rules="[validators.required]"
                    :error-messages="errors.lat"
                    @change="errors.lat = []"
                  ></v-text-field>
                  <v-text-field
                    v-model="formData.lng"
                    outlined
                    dense
                    label="Longitude *"
                    hide-details="auto"
                    class="mb-6"
                    :rules="[validators.required]"
                    :error-messages="errors.lng"
                    @change="errors.lng = []"
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    block
                    type="submit"
                  >
                    {{ formType === 'editItem'?'Save':'Add' }}
                  </v-btn>
                </v-tab-item>
                <v-tab-item key="image">
                  <v-text-field
                    v-if="formData.image !== null"
                    :value="formData.image.filename"
                    readonly
                    clearable
                    outlined
                    dense
                    label="Image file"
                    hide-details="auto"
                    class="mb-6"
                    :error-messages="errors.image_file"
                    @click:clear="clearImage"
                  ></v-text-field>
                  <v-file-input
                    v-if="formData.image === null"
                    v-model="formData.image_file"
                    :aspect-ratio="16/9"
                    accept="image/*"
                    autofocus
                    label="Image file"
                    outlined
                    dense
                    prepend-icon=""
                    class="mb-6"
                    hide-details="auto"
                    clearable
                    :error-messages="errors.image_file"
                    @change="loadImage"
                  ></v-file-input>
                  <v-img :src="url"></v-img>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<style>
.v-application.theme--light .v-tabs:not(.v-tabs--vertical),
.theme--light.v-tabs > .v-tabs-bar {
	box-shadow: none !important;
	background-color: transparent !important;
	border-color: transparent !important;
}
</style>

<script>
import { mdiClose } from '@mdi/js'
import { mapGetters } from 'vuex'
import { required } from '@core/utils/validation'
import _ from 'lodash'
import CONSTANTS from '../../constants'

const blankFormData = {
	name: '',
	lat: null,
	lng: null,
	street_address: '',
	city: '',
	country_id: null,
	postal_code: '',
	image_file: null,
	image_url: '',
	image: null,
}
const initial = { lat: 51.477928, lng: -0.001545 }

export default {
	props: {
		show: { type: Boolean },
		formType: {
			type: String,
			default() {
				return 'addItem'
			},
		},
	},
	data() {
		return {
			url: null,
			tab: 0,
			initial,
			formData: _.cloneDeep(blankFormData),
			form: null,
			valid: true,
			errors: {},
			validators: { required },
			icons: { mdiClose },
			options: {
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				disableDefaultUi: false,
			},
		}
	},
	computed: {
		isVisible: {
			get() {
				return this.show
			},
			set(val) {
				this.$emit('update:show', val)
			},
		},
		...mapGetters('country', ['countries']),
	},
	mounted() {
		this.getCountries()
	},
	methods: {
		getCountries() {
			this.$store.dispatch('country/all')
		},
		clearImage() {
			this.formData.image = null
			this.loadImage()
		},
		loadImage() {
			this.errors.image_file = []
			if (this.formData.image_file != null) {
				this.url = URL.createObjectURL(this.formData.image_file)
			} else if (this.formData.image != null) {
				this.url = this.formData.image_url
			} else {
				this.url = CONSTANTS.NO_IMAGE
			}
		},
		markerMoved(event) {
			this.formData.lat = event.latLng.lat()
			this.formData.lng = event.latLng.lng()
			this.$refs.mapRef.$mapPromise.then(map => {
				map.panTo({ lat: this.formData.lat, lng: this.formData.lng })
			})
		},
		addressSelected(event) {
			this.formData.lat = event.geometry.location.lat()
			this.formData.lng = event.geometry.location.lng()
			const a = []
			_.each(event.address_components, (k, v1) => {
				_.each(event.address_components[v1].types, (k2, v2) => {
					a[event.address_components[v1].types[v2]] = event.address_components[v1].long_name
				})
			})
			this.formData.street_address = a.street_number === undefined ? a.route : `${a.street_number}, ${a.route}`
			this.formData.city = a.locality || a.administrative_area_level_1
			this.formData.postal_code = a.postal_code
			this.formData.country_id = this.countries.find(element => element.nicename === a.country)?.id
			this.$refs.mapRef.$mapPromise.then(map => {
				map.panTo({ lat: this.formData.lat, lng: this.formData.lng })
			})
		},
		resetFormValidation() {
			this.$refs.form.resetValidation()
			this.$set(this, 'errors', {})
			this.$set(this, 'valid', this.formType !== 'addItem')
		},
		loadFormData(data) {
			this.resetFormValidation()
			this.formData = _.cloneDeep(data)
			this.loadImage()
			if (data.lat != null && data.lng != null) {
				this.$refs.mapRef.$mapPromise.then(map => {
					map.panTo({ lat: data.lat, lng: data.lng })
				})
			}
		},
		prepareFromData() {
			const formData = new FormData()
			Object.entries(this.formData).forEach(([key, value]) => {
				formData.append(key, value)
			})

			return formData
		},
		store() {
			const formData = this.prepareFromData()
			this.$store
				.dispatch('outlet/store', formData)
				.then(response => {
					this.isVisible = false
					this.$emit('onComplete')
					this.$store.commit('app/SET_SNACKBAR_SUCCESS', response.data?.message)
				})
				.catch(error => {
					const { errors } = error.response.data
					if (errors) {
						this.$set(this, 'errors', errors)
						this.$set(this, 'valid', false)
					}
					this.$store.commit('app/SET_SNACKBAR_ERROR', error.response.data?.message)
				})
		},
		update() {
			const formData = this.prepareFromData()
			this.$store
				.dispatch('outlet/update', formData)
				.then(response => {
					this.isVisible = false
					this.$emit('onComplete')
					this.$store.commit('app/SET_SNACKBAR_SUCCESS', response.data?.message)
				})
				.catch(error => {
					const { errors } = error.response.data
					if (errors) {
						this.$set(this, 'errors', errors)
						this.$set(this, 'valid', false)
					}
					this.$store.commit('app/SET_SNACKBAR_ERROR', error.response.data?.message)
				})
		},
		onSubmit() {
			if (this.valid) {
				const methodType = this.formType === 'editItem' ? 'update' : 'store'
				this[methodType]()
			} else {
				this.$refs.form.validate()
			}
		},
	},
}
</script>

<style>
* {
	box-sizing: border-box;
}
.forms {
	position: relative;
	max-width: 300px;
}
.forms input {
	width: 100%;
	height: 40px;
	transition: 0.3s;
	padding: 0 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-bottom: 2em;
	color: rgba(94, 86, 105, 0.87);
}
.forms label {
	color: rgba(94, 86, 105, 0.68);
	pointer-events: none;
	position: absolute;
	top: 7px;
	left: 15px;
	transition: 0.3s;
	font-size: 16px;
}
.forms input:focus {
	outline: none;
	border: 2px solid var(--v-primary-base);
}
.forms input:focus + label {
	top: -7px;
	left: 0.5em;
	background: #fff;
	padding: 0 0.3em;
	transition: 0.3s;
	font-size: 0.8em;
	color: var(--v-primary-base) !important;
}
.forms input:valid + label {
	top: -7px;
	left: 0.5em;
	background: #fff;
	padding: 0 0.3em;
	transition: 0.3s;
	font-size: 0.8em;
	color: rgba(94, 86, 105, 0.68);
}
</style>
