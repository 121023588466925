<template>
  <v-card>
    <v-card-title>Outlets</v-card-title>
    <div>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
              @keyup="searchData"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <div class="text-center">
              <v-btn
                color="primary"
                block
                @click="openAddForm"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon> New Outlet
              </v-btn>
              <outlet-form
                ref="form"
                :show.sync="isFormVisible"
                :form-type="formType"
                @onComplete="datatable"
              ></outlet-form>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :server-items-length="totalItems"
        class="table-kitchen-sink"
      >
        <!-- Image -->
        <template v-slot:item.image_url="{ item }">
          <v-img
            contain
            height="64"
            max-width="64"
            :src="item.image_url"
          ></v-img>
        </template>
        <!-- Actions -->
        <template v-slot:item.actions="{ item }">
          <!-- Edit button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="openEditForm(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <!-- Delete button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="deleteItemConfirmation(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>

          <!-- Delete dialog -->
          <c-dialog
            v-model="isDeleteItemDialogVisible"
            @onConfirm="deleteItem"
          >
            Are you sure want to delete '{{ selectedItem.name }}'?
          </c-dialog>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import { mdiPlus, mdiMagnify, mdiPencilOutline, mdiDeleteOutline } from '@mdi/js'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import OutletForm from './Form.vue'

const blankFormData = {
	name: '',
	lat: null,
	lng: null,
	street_address: '',
	city: '',
	country_id: null,
	postal_code: '',
	image_file: null,
	image_url: '',
	image: null,
}

export default {
	components: { OutletForm },
	data() {
		return {
			formType: 'addItem',
			selectedItem: {},
			isDeleteItemDialogVisible: false,
			isFormVisible: false,
			headers: [
				{ text: 'NAME', value: 'name' },
				{ text: 'Address', value: 'address' },
				{ text: 'Image', value: 'image_url' },
				{
					text: 'ACTIONS',
					value: 'actions',
					sortable: false,
					width: '150px',
				},
			],
			icons: {
				mdiPlus,
				mdiMagnify,
				mdiPencilOutline,
				mdiDeleteOutline,
			},
		}
	},
	computed: {
		...mapGetters('outlet', ['items', 'totalItems']),
		options: {
			get() {
				return this.$store.getters['outlet/options']
			},
			set(value) {
				this.$store.commit('outlet/SET_OPTIONS', value)
			},
		},
		search: {
			get() {
				return this.$store.getters['outlet/search']
			},
			set(value) {
				this.$store.commit('outlet/SET_SEARCH', value)
			},
		},
	},
	watch: {
		options() {
			this.datatable()
		},
	},
	methods: {
		searchData: _.debounce(function fn() {
			this.datatable()
		}, 400),
		datatable() {
			this.$store.dispatch('outlet/datatable')
		},
		openAddForm() {
			this.$refs.form.loadFormData(blankFormData)
			this.formType = 'addItem'
			this.isFormVisible = true
		},
		openEditForm(item) {
			this.$refs.form.loadFormData(item)
			this.formType = 'editItem'
			this.isFormVisible = true
		},
		deleteItemConfirmation(item) {
			this.selectedItem = JSON.parse(JSON.stringify(item))
			this.isDeleteItemDialogVisible = true
		},
		deleteItem() {
			this.$store
				.dispatch('outlet/destroy', this.selectedItem.id)
				.then(response => {
					this.$store.commit('app/SET_SNACKBAR_SUCCESS', response.data?.message)
					this.datatable()
				})
				.catch(error => {
					this.$store.commit('app/SET_SNACKBAR_ERROR', error.response.data?.message)
				})
		},
	},
}
</script>
